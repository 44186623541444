import "./set-public-path";
import "./styles.scss"
// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() {
    console.log("Saludos desde styleguide")
}

export { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBTypography } from 'mdbreact';

export { Line, HorizontalBar  } from "react-chartjs-2";

export { BrowserRouter, Link, Switch, Route} from "react-router-dom";

import PacmanLoader1 from "react-spinners/PacmanLoader";

export const PacmanLoader=PacmanLoader1

export { css } from "@emotion/core";

import CountUp1 from 'react-countup';

export const CountUp=CountUp1

export { FaUserCircle, FaUsers, FaUserCheck, FaDownload } from 'react-icons/fa';

export { RiUserSearchFill } from "react-icons/ri";

export { Document, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, VerticalAlign, TextDirection} from "docx"

export { saveAs } from "file-saver";